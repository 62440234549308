<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <v-card class="rounded-lg clickable" @click="goToArticle(article)">
                    <v-img
                        :src="getArticleImage()"
                        class="white--text align-end"
                        gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
                        height="300px"
                        contain
                    >
                        <v-card-title class="custom-orange" style="text-align: justify; word-break: break-word;" v-text="article.title" />
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    // import momentTimezone from 'moment-timezone'
    export default {
        props: {
            article: {
                type: Object,
                default: null
            }
        },
        methods: {
            getArticleImage() {
                return `${'https://storage.googleapis.com'}/${this.article.image}`
            },
            goToArticle(article) {
                this.$router.push({ name: 'Article', params: { id: article.id } })
            }
        }
    }
</script>
