<template>
    <v-container fluid>
        <v-row align="center" justify="center">
            <v-col cols="12" align="center" class="py-0 my-0mx-0 px-0">
                <v-card max-width="1200px" class="jumbotron-top custom-orange elevation-11 rounded-lg px-5">
                    <vue-marquee-slider
                        id="marquee-slider"
                        :speed="10000"
                        :width="750"
                        class="pt-1"
                    >
                        <v-card height="50px" color="transparent" flat>
                            <v-row justify="start" align="center">
                                <v-col cols="3">
                                    <img height="50px" :src="require('@/assets/main_logo.png')">
                                </v-col>
                                <v-col cols="9">
                                    <span class="white--text secondray-font text-h6">{{ articles[0].title }}</span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </vue-marquee-slider>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-3 mb-0 py-0">
            <v-col cols="12" align="center" class="py-0 my-0">
                <v-card min-height="350px" max-height="550px" max-width="1100px" class="rounded-xl elevation-6 clickable" @click="goToArticle(articles[0])">
                    <v-img min-height="350px" max-height="550px" class="white--text align-end" gradient="to bottom, rgba(0,0,0,0), rgba(0,0,0,.6)" stretch :src="getArticleImage()">
                        <v-card-title class="text-h5" style="word-break: break-word;" v-text="articles[0].title" />
                    </v-img>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="py-0 my-0 rounded-lg">
            <v-col cols="1" align="center" class="py-0 my-0" />
            <v-col cols="10" class="py-0 my-0" align="center">
                <v-card max-width="900px" class="jumbotron-bottom custom-orange rounded-xl px-5 elevation-1">
                    <vue-marquee-slider
                        id="marquee-slider-2"
                        :speed="10000"
                        :width="750"
                        class="pt-1"
                    >
                        <v-card height="40px" color="transparent" flat>
                            <v-row justify="center" align="center">
                                <v-col cols="3">
                                    <img height="40px" contain :src="require('@/assets/main_logo.png')">
                                </v-col>
                                <v-col cols="9">
                                    <span class="white--text secondray-font text-h6">{{ articles[0].title }}</span>
                                </v-col>
                            </v-row>
                        </v-card>
                    </vue-marquee-slider>
                </v-card>
            </v-col>
            <v-col cols="1" align="center" class="py-0 my-0" />
        </v-row>
        <v-row>
            <v-col v-for="(article, index) in articles.slice(1)" :key="index" cols="12" md="6">
                <article-card :article="article" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ArticleCard from '@/components/ArticleCard'
    import articleService from '@/services/article'

    export default {
        components: { ArticleCard },
        data() {
            return {
                page: 1,
                size: 7,
                totalPages: 0,
                articles: []
            }
        },
        created() {
            this.fetchArticles()
        },
        mounted() {
            this.loadMore()
        },
        methods: {
            goToArticle(article) {
                this.$router.push({ name: 'Article', params: { id: article.id } })
            },
            getArticleImage() {
                return `${'https://storage.googleapis.com'}/${this.articles[0].image}`
            },
            loadMore() {
                this.fetchNextData()
            },
            fetchNextData() {
                window.onscroll = () => {
                    const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
                    if (bottomOfWindow) {
                        if (this.page < this.totalPages) {
                            this.page += 1
                            this.fetchArticles()
                        }
                    }
                }
            },
            fetchArticles() {
                articleService.getArticles(this.page, this.size).then(resp => {
                    this.articles.push(...resp.data.content)
                    this.totalPages = resp.data.totalPages
                })
            }
        }
    }
</script>

<style scoped>
.jumbotron-top {
    height: 65px;
}

.jumbotron {
    /* clip-path: polygon(0 0, 100% 1%, 96% 100%, 4% 100%); */
    background-color: white;
    height: 550px;
}

@media only screen and (max-width: 1000px) {
    .jumbotron {
        /* clip-path: polygon(0 0, 100% 1%, 96% 100%, 4% 100%); */
        background-color: white;
        height: 300px;
    }
}

.jumbotron-bottom {
    height: 55px;
    background-color: black;
}
</style>
